<template>
    <div class="container">
        <div class="row mb-3">
            <div class="col-12">
                <router-link
                    class="tab_btn"
                    v-for="pc in productList"
                    :class="nowtab == pc.id ? 'active' : ''"
                    :to="`#${pc.id}`"
                    :key="`tab_${pc.id}`"
                    >{{ pc.name }}
                </router-link>
            </div>
        </div>
        <transition-group name="slide-fade">
            <div
                class="row mb-5"
                v-for="pc in productList"
                v-show="nowtab == pc.id"
                :key="`con_${pc.id}`"
            >
                <div
                    class="col-md-6 col-lg-4"
                    v-for="p in pc.product"
                    :key="`pro_${p.id}`"
                >
                    <productUnit :product="p"></productUnit>
                </div>
            </div>
        </transition-group>
    </div>
</template>
<script>
import productUnit from "./product_unit.vue";
export default {
    components: { productUnit },
    data() {
        return {
            nowtab: "0"
        };
    },
    mounted() {
        this.switch_tab();
    },
    watch: {
        "$route.hash"() {
            this.switch_tab();
        }
    },
    computed: {
        productList() {
            let output = {
                "0": {
                    id: "0",
                    name: this.$store.state.language.product.all,
                    product: []
                }
            };
            if (this.$store.state.data.product_catg) {
                this.$store.state.data.product_catg.forEach(catg => {
                    output[catg.id] = {
                        id: catg.id,
                        name: catg.name,
                        product: []
                    };
                });
            }
            if (this.$store.state.data.product) {
                this.$store.state.data.product.forEach(p => {
                    output["0"].product.push(p);
                    output[p.id_product_catg].product.push(p);
                });
            }
            return output;
        }
    },
    methods: {
        switch_tab() {
            this.nowtab = !this.$route.hash ? "0" : this.$route.hash.substr(1);
        }
    }
};
</script>
